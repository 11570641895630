export const environment = {
  production: false,
  apiUrl: 'https://dev-api.dogoodphil.com/v4',
  pubKey: '',
  subKey: 'sub-c-59131e57-ce71-44fc-b378-b72b84850e3b',
  mixpanelToken: '2c2ae1260afc52c10305e53928c68407',
  assetsUrl: 'https://dev-assets.dogoodphil.com',
  sentryDns:
    'https://2f7f80b8b5881f1c39dbd9f87888a149@o138343.ingest.sentry.io/4506204365979648',
  appId: 'dgp',
  firebase: {
    apiKey: 'AIzaSyBaAqbS3hhaZegb5xmqpZlcmjRN-W3nqwM',
    authDomain: 'dash-production.firebaseapp.com',
    databaseURL: 'https://dash-production.firebaseio.com',
    projectId: 'dash-production',
    storageBucket: 'dash-production.appspot.com',
    messagingSenderId: '1058188252524',
    appId: '1:1058188252524:web:4a7045334104b4b4eacf49',
    measurementId: 'G-B2E3FK60HN',
    vapidKey:
      'BFvprYc4OrPY44hTveO_qapEaorBiZc954WkoSoNDQWqSjeef3OEIph_1qJ6d_Da1zw_bFM17M8cm0Jt0QfaKa0',
  },
};
